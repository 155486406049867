export const modalStyleInject = () => {
	return `
    .modal{
    position: fixed;
	inset: 0;
	pointer-events: none;
	z-index:10000006 !important;
    }

    .modalContainer {
        position: fixed;
		right: 0;
		height: 100%;
			z-index:10000006 !important;
    }

    .modalContent {
		transition: all 350ms ease-in-out;
		opacity: 0;
		transform: translateX(100%);
        height: 100%;
	}

    .active {
		transform: translateX(0);
		opacity: 1;
	}

	.hideModal-xsc{
	pointer-events: none;
	}
    `;
};
