import React, { useEffect, useState } from "react";
import { scrollPage } from "../utils/scroll";


const TypewriterEffect = ({ text, speed, streaming }: { text: string; speed: number, streaming: boolean }) => {
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);
  
  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText((prevText) => prevText + text[index]);
        setIndex((prevIndex) => prevIndex + 1);
    
      }, speed);
      scrollPage(true);
      return () => clearTimeout(timeout);
    }
  }, [index, text, speed]);

  return <span dangerouslySetInnerHTML={{ __html: displayText }} />;
};

export default TypewriterEffect;
