import React, { useState, useEffect, useCallback } from "react";
import styles from "./GeneralModal.module.scss";
import { generalModalStyleInject } from "../../../injectedStyles/generalModalStyle";

interface PROPS {
	isOpen: boolean;
	title: string;
	setTriggerClose?: React.Dispatch<React.SetStateAction<boolean>>;
	triggerClose?: boolean;
	onClose: () => void;
	content: React.ReactNode;
}

const GeneralModal: React.FC<PROPS> = ({
	isOpen,
	title,
	setTriggerClose,
	triggerClose,
	onClose,
	content,
}) => {
	const [modalActive, setModalActive] = useState(isOpen);

	useEffect(() => {
		if (isOpen) {
			setModalActive(isOpen);
		}
	}, [isOpen]);

	const handleClose = useCallback(() => {
		if (setTriggerClose) {
			setModalActive(false);
			setTriggerClose(false);
			setTimeout(() => {
				onClose();
			}, 350);
		} else {
			setModalActive(false);
			setTimeout(() => {
				onClose();
			}, 350);
		}
	}, [onClose, setTriggerClose]);

	useEffect(() => {
		if (triggerClose) {
			handleClose();
		}
	}, [triggerClose, handleClose]);

	const modalStyle = () => {
		if (modalActive) {
			return `generalModalContent generalModalActive ${styles.generalModalContent} ${styles.generalModalActive}`;
		} else {
			return `generalModalContent  ${styles.generalModalContent}`;
		}
	};

	if (!isOpen) {
		return null;
	}
	return (
		<main className={`generalModal ${styles.generalModal}`}>
			<style>{generalModalStyleInject()}</style>
			<div className={`generalModalContainer ${styles.generalModalContainer}`}>
				<div className={` ${modalStyle()}`}>
					<div
						className={`modalItem modalItemActive ${
							modalActive ? styles.modalItemActive : ""
						} ${styles.modalItem}`}
					>
						<div className={`${styles.heading} generalMHeading`}>
							<p className={`${styles.title} titleHgenral BaseFont2`}>{title}</p>
							<div
						role="button"
						className={`close-xes-btn ${styles.close}`}
						style={{ background: `#000` }}
						onClick={handleClose}
					>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13 1L1 13"
								stroke="#fff"
								strokeWidth="3"
								strokeLinecap="round"
							/>
							<path
								d="M1 1L13 13"
								stroke="#fff"
								strokeWidth="3"
								strokeLinecap="round"
							/>
						</svg>
					</div>
						</div>
						<div className={`${styles.mainContent} mainContentGeneral`}>
						{content}
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default GeneralModal;
