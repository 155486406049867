import React, { useEffect, useRef, useCallback } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import SyntaxHighlighterWrapper from "./forwardRefs";
import { preprocessChildren } from "../utils/replaceMarkDown";
import TypewriterEffect from "./TypewriterEffect";

const UseMarkdown = ({
	children,
	ClassName = "",
	streaming = false,
	Messages,
}: {
	children: string;
	ClassName?: string;
	streaming?: boolean;
	Messages: any;
}) => {
	const processedChildren = typeof children === "string" ? 
		streaming ? 
			preprocessChildren(children.replace(/###\s*\d+\.\s*([^\n]+)/g, '<h3>$1</h3>')) :
			preprocessChildren(children) : 
		children;
		
	const hasCodeBlocks = /```/.test(children ?? "");
	const chartRef = useRef<HTMLDivElement | null>(null);
	const chartMatch = children.match(/<code_analyse>([\s\S]*?)<\/code_analyse>/);

	const formatCodeBlock = useCallback((code: string) => {
		console.log(code, "code");
		return code?.replace(/\\n/g, "\n")
			?.replace(/\\"/g, '" ')
			?.trim();
	}, []);

	const decodeHtmlEntities = (str: string): string => {
		const textarea = document.createElement("textarea");
		textarea.innerHTML = str;
		return textarea.value;
	};

	const extractAndReplaceImages = (content: string) => {
		const imageRegex = /<image\s+src="([^"]+?)"\s*\/>/g;
		const images: string[] = [];
		const decodedContent = decodeHtmlEntities(content).replace(/\\n/g, "\n");
		const preprocessedContent = decodedContent.replace(/<em>|<\/em>/g, "_");

		let match;
		while ((match = imageRegex.exec(preprocessedContent)) !== null) {
			const url = match[1].trim();
			try {
				new URL(url);
				images.push(url);
			} catch {
				// 
			}
		}

		const contentWithoutImages = preprocessedContent
			.replace(imageRegex, "")
			.replace(/(<br\s*\/?>)+/g, "<br><br>");
		return { images, contentWithoutImages };
	};

	const renderImagesAsStyledGrid = (images: string[]) => {
		if (images.length === 0) return "";
	
		const isOdd = images.length % 2 !== 0;
		const imageDivs = images
		  .map((url, index) => {
			const isLastAndOdd = isOdd && index === images.length - 1;
			const className = isLastAndOdd ? "full-width" : "square";
			return `<div key=${index} class="image-est90 ${className}">
					  <img src="${url}" alt="Image ${index + 1}" class="image-exe-tro" />
					</div>`;
		  })
		  .join("");
	
		return `<div class="image-container ${isOdd ? "odd-count" : ""}">
				  ${imageDivs}
				</div>`;
	  };
	  
	useEffect(() => {
		const renderChart = () => {
			if (chartMatch && chartRef.current) {
				try {
					chartRef.current.innerHTML = "";
					const scriptContent = formatCodeBlock(chartMatch[1]);
					const parser = new DOMParser();
					const doc = parser.parseFromString(scriptContent, "text/html");
					const canvas = doc.querySelector("canvas");
					const scripts = doc.querySelectorAll("script");
					if (canvas) chartRef.current.appendChild(canvas);
					scripts.forEach((script) => {
						if (script.textContent) {
							const executeScript = new Function(script.textContent);
							executeScript();
						}
					});
				} catch (error) {
					console.error("Failed to render chart:", error);
				}
			}
		};
		
		renderChart();
	}, [chartMatch, formatCodeBlock]);

	if (hasCodeBlocks || !hasCodeBlocks) {
		return (
			<Markdown
				children={formatCodeBlock(children)}
				className={ClassName}
				components={{
					code({ node, ...props }) {
						const match = /language-(\w+)/.exec(props.className || "");
						return match ? (
							<SyntaxHighlighterWrapper {...props} language={match[1]}>
								{props.children}
							</SyntaxHighlighterWrapper>
						) : (
							<code
								{...props}
								className={props.className}
								style={{
									backgroundColor: "#f5f5f5",
									padding: "2px 4px",
									borderRadius: "4px",
									fontFamily: "monospace",
									fontSize: "13px",
									color: "#555",
									fontStyle: "italic",
									whiteSpace: "pre-wrap",
									wordBreak: "break-word",
								}}
							>
								{props.children}
							</code>
						);
					},
					...Array.from({ length: 6 }, (_, i) => {
						const Tag = `h${i + 1}` as keyof JSX.IntrinsicElements;
						return {
							[Tag]: ({ node, children, ...props }: any) => (
								<Tag {...props} style={{ marginBlock: "10px" }}>
									{children}
								</Tag>
							),
						};
					}).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
					ul: ({ node, ...props }) => (
						<ul {...props} style={{ marginTop: "10px", marginBottom: "10px", display: "flex", flexDirection: "column", gap: "1.2rem" }} />
					),
					ol: ({ node, ...props }) => (
						<ol {...props} style={{ marginTop: "10px", marginBottom: "5px", display: "flex", flexDirection: "column", gap: "1.2rem" }} />
					),
				}}
				remarkPlugins={[remarkGfm, remarkBreaks]}
			/>
		);
	}

	const { images, contentWithoutImages } = extractAndReplaceImages(processedChildren as string);

	return (
		<div className={ClassName}>
			<TypewriterEffect text={contentWithoutImages} speed={4} streaming={streaming} />
			{images.length > 0 && renderImagesAsStyledGrid(images)}
			<div ref={chartRef}></div>
		</div>
	);
};

export default UseMarkdown;
