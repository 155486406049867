import React from "react";
import styles from "./EntryPage.module.scss";
import PromptBox from "../promptBox/PromptBox";
import useBrand from "../../store/brandStore";
import { BrandState } from "../../store/brandStore";

interface EntryPageProps {
	firstName?: string;
	randomPrompts: string[];
	setText: (text: string) => void;
	textareaRef: React.RefObject<HTMLTextAreaElement>;
	text: string;
	generating: boolean;
	sendMessage: () => void;
	handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	sendDisabled: string | React.ReactNode;
	stopStream: () => void;
	stop?: string | React.ReactNode;
}

const EntryPage = ({
	firstName,
	randomPrompts,
	setText,
	textareaRef,
	text,
	generating,
	sendMessage,
	handleChange,
	sendDisabled,
	stopStream,
	stop,
}: EntryPageProps) => {
	const { brandColor } = useBrand((state: BrandState) => state);
	const getContrastColor = (hexcolor: string) => {
		const hex = hexcolor.replace("#", "");
		const r = parseInt(hex.substr(0, 2), 16);
		const g = parseInt(hex.substr(2, 2), 16);
		const b = parseInt(hex.substr(4, 2), 16);

		const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
		return luminance > 0.5 ? "#000000" : "#FFFFFF";
	};

	return (
		<section className={`welcome BaseFont ${styles.welcome}`}>
			<div className={`top ${styles.top}`}>
				<div
					className={`greeting-container ${styles.greetingContainer}`}
				>
					<p className={`greeting BaseFont ${styles.greeting}`} style={{ color: getContrastColor(brandColor) }}>Hi {firstName},</p>
					<p className={`spanGreeting BaseFont2 ${styles.spanGreeting}`} style={{ color: getContrastColor(brandColor) }}>
						{" "}
						I'm an AI assistant trained to transform your business by delivering
						real-time insights and actionable data with precision.
					</p>
				</div>
			</div>

			<div className={`bottom ${styles.bottom}`}>
				<p className={`suggestedPrompts BaseFont ${styles.suggestedPrompts}`}>
					Quick Prompts
				</p>
				<div className={`randomPromptsContainer ${styles.randomPromptsContainer}`}>
					{randomPrompts?.map((itm, idx) => {
						return (
							<button
								key={idx}
								onClick={() => {
									setText(itm);
									textareaRef.current?.focus();
								}}
								className={`randomPrompt BaseFont2 ${styles.randomPrompt}`}
							>
								{itm}
							</button>
						);
					})}
				</div>

				<div className={`promptBoxHome ${styles.promptBoxHome}`}>
					<PromptBox
						text={text}
						generating={generating}
						sendMessage={sendMessage}
						stopStream={stopStream}
						handleChange={handleChange}
						textareaRef={textareaRef}
					/>
				</div>
			</div>
		</section>
	);
};

export default EntryPage;
