import React, { useEffect, useRef } from "react";
import styles from "./PromptBox.module.scss";
import SVGIcon from "../chatPopUp/svgs";
import useBrand, { BrandState } from "../../store/brandStore";

interface Props {
    text: string;
    generating: boolean;
    sendMessage: () => void;
    stopStream: () => void;
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    textareaRef: React.RefObject<HTMLTextAreaElement>;
}

const PromptBox = ({
    text,
    generating,
    sendMessage,
    stopStream,
    handleChange,
}: Props) => {
    const icons = SVGIcon();
    const { secondaryColor } = useBrand((state: BrandState) => state);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [text, textareaRef]);

    return (
        <div className={`bottom-xs-exe ${styles.bottomA}`}>
            <section className={`textArea-xs-exe ${styles.textArea}`}>
                <textarea
                    rows={1}
                    id="prompt_box"
                    ref={textareaRef}
                    onKeyDown={(e) => {
                        if (
                            e.key === "Enter" &&
                            !e.shiftKey &&
                            text.trim().length > 0 &&
                            !generating
                        ) {
                            e.preventDefault();
                            sendMessage();
                        }
                    }}
                    value={text}
                    placeholder={"Ask Snapshot Anything"}
                    className={`text-xs-exe ${styles.text}`}
                    style={{ fontFamily: "CircularStd, sans-serif" }}
                    onChange={handleChange}
                />
                <section className={`actionBtns ${styles.actionBtns}`}>
                    {!generating && (
                        <button
                            disabled={text.trim().length < 1}
                            onClick={sendMessage}
                            style={{ background: `#${secondaryColor}` ?? "#3F8D81" }}
                            className={`sendBtn ${styles.sendBtn} ${
                                text.trim().length < 1 && `sendBtnAlt ${styles.sendBtnAlt}`
                            }`}
                        >
                            {icons.sendDisabled}
                        </button>
                    )}

                    {generating && (
                        <button
                            onClick={stopStream}
                            style={{ background: `#${secondaryColor}` ?? "#3F8D81" }}
                            className={`sendBtn ${styles.sendBtn}`}
                        >
                            {icons.stop}
                        </button>
                    )}
                </section>
            </section>
        </div>
    );
};

export default PromptBox;
