import React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import SyntaxHighlighterWrapper from "./forwardRefs";
import { preprocessChildren } from "../utils/replaceMarkDown";
import TypewriterEffect from "./TypewriterEffect";

const UseMarkdown = ({ children, ClassName, streaming }: { children: string; ClassName: string, streaming: boolean }) => {
  const processedChildren =
    typeof children === "string" ? preprocessChildren(children) : children;

  const hasCodeBlocks = /```/.test(children ?? "");
  const formatCodeBlock = (code: string) => {
    return code?.replace(/\\n/g, "\n")?.replace(/\\"/, "\n")?.trim();
  };

  const extractAndReplaceImages = (content: string) => {
    const imageRegex = /<image\s+src="([^"]+)"\s*\/>/g;
    const images = [];
    let match;

    while ((match = imageRegex.exec(content)) !== null) {
      images.push(match[1]);
    }

    const contentWithoutImages = content
      .replace(imageRegex, "")
      .replace(/(<br\s*\/?>)+/g, "<br><br>");
    return { images, contentWithoutImages };
  };

  const renderImagesAsStyledGrid = (images: string[]) => {
    if (images.length === 0) return "";

    const isOdd = images.length % 2 !== 0;
    const imageDivs = images
      .map((url, index) => {
        const isLastAndOdd = isOdd && index === images.length - 1;
        const className = isLastAndOdd ? "full-width" : "square";
        return `<div key=${index} class="image-est90 ${className}">
                  <img src="${url}" alt="Image ${index + 1}" class="image-exe-tro" />
                </div>`;
      })
      .join("");

    return `<div class="image-container ${isOdd ? "odd-count" : ""}">
              ${imageDivs}
            </div>`;
  };

  if (hasCodeBlocks) {
    return (
      <Markdown
        children={formatCodeBlock(children)}
        className={ClassName}
        remarkPlugins={[remarkGfm, remarkBreaks]}
        components={{
          h4: ({ children }) => (
            <h4 style={{
              fontSize: '1.5em',
              fontWeight: 'bold',
              margin: '1em 0'
            }}>
              {children}
            </h4>
          ),
          code({ children, className, ...rest }) {
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <SyntaxHighlighterWrapper {...rest} language={match[1]}>
                {formatCodeBlock(children as string)}
              </SyntaxHighlighterWrapper>
            ) : (
              <code
                {...rest}
                className={className}
                style={{
                  backgroundColor: "#f5f5f5",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  fontFamily: "monospace",
                  fontSize: "13px",
                  color: "#555",
                  fontStyle: "italic",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                {children}
              </code>
            );
          },
        }}
      />
    );
  }

  const { images, contentWithoutImages } = extractAndReplaceImages(processedChildren as string);

  const updatedContent =
    images.length > 0
      ? contentWithoutImages.replace(
          "<br><br>",
          `<br><br>${renderImagesAsStyledGrid(images)}<br><br>`
        )
      : contentWithoutImages;

  return (
    <div className={ClassName}>
      <TypewriterEffect text={updatedContent} speed={10} streaming={streaming} />
    </div>
  );
};

export default UseMarkdown;
