import React from "react";
import styles from "./AllSources.module.scss";
import GeneralModal from "../GeneralModal";
import { ModalType, useAllSourcesModal } from "../../../../hooks/usePopUpModals";
import { useSourcesStore } from "../../../../store/sourcesStore";
import { SourcesState, Source } from "../../../../store/sourcesStore";
import { allSourcesStyleInject } from "./allSourcesStyle";
const AllSources = () => {
	const { isOpen, onClose } = useAllSourcesModal((state: ModalType) => state);
	const { sources: RenderSources } = useSourcesStore((state: SourcesState) => state);
	const sources: Source[] = RenderSources;

	const content = (
		<main className={`${styles.main} mainAllSources`}>
			<style>{allSourcesStyleInject()}</style>
			<div className={`${styles.sourcesContainer} sourcesContainer-exe`}>
				{sources &&
					sources.map((source) => (
						<div
							role="button"
							onClick={() => window.open(source.link, "_blank")}
							key={source.link}
							className={`${styles.card} sourcesCard-exe`}
							style={{ padding: "10px" }}
							onMouseEnter={(e) => {
								Object.assign(e.currentTarget.style, {
									background: "#F5F7F6",
									cursor: "pointer",
								});
							}}
							onMouseLeave={(e) => {
								Object.assign(e.currentTarget.style, {
									background: "initial",
									transition: "all 0.3s ease",
								});
							}}
						>
							<div style={{ display: "flex", gap: "8px" }}>
								{source.favicon && (
									<img
										src={source?.favicon || ""}
										alt="favicon"
										width={20}
										height={20}
										style={{ borderRadius: "50%" }}
									/>
								)}
								<p
									style={{ color: "#000", fontSize: "14px", fontWeight: "600", textDecoration:"underline" }}
									className={`BaseFont2`}
								>
									{source?.title}
								</p>
							</div>
							<p
								style={{ color: "#6D6A6A", fontSize: "14px" }}
								className={`BaseFont2`}
							>
								{source?.snippet}
							</p>
						</div>
					))}
			</div>
		</main>
	);
	return <GeneralModal title="All Sources" isOpen={isOpen} onClose={onClose} content={content} />;
};

export default AllSources;
