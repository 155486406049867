import { getContrastColor } from "../../utils/getContrastColor";
import useBrand from "../../store/brandStore";
import { BrandState } from "../../store/brandStore";

const SVGIcon = () => {
	const { secondaryColor } = useBrand((state: BrandState) => state);

	const icons = {
		sendDisabled: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				fill="#000"
				viewBox="0 0 32 32"
				className="icon-2xl"
			>
				<path
				fill={getContrastColor(secondaryColor)}
					fillRule="evenodd"
					d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z"
					clipRule="evenodd"
				></path>
			</svg>
		),
		sendEnabled: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				fill="#fff"
				viewBox="0 0 32 32"
				className="icon-2xl"
			>
				<path
					fill={getContrastColor(secondaryColor)}
					fillRule="evenodd"
					d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z"
					clipRule="evenodd"
				></path>
			</svg>
		),
		stop: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="32"
				fill="#fff"
				viewBox="0 0 24 24"
			>
				<rect width="10" height="10" x="7" y="7" fill="#fff" rx="1.25"></rect>
			</svg>
		),
	};

	return icons;
};

export default SVGIcon;
