import { create } from "zustand";

export interface BrandState {
	brandLogo: string;
	brandColor: string;
	loading: boolean;
	secondaryColor: string;
	setBrandLogo: (brandLogo: string | null) => void;
	setBrandColor: (brandColor: string) => void;
	setSecondaryColor: (color: string) => void;
	setLoading: (loading: boolean) => void;
}

const useBrand = create<BrandState>((set) => ({
	brandLogo: "",
	brandColor: "",
	secondaryColor: "",
	loading: false,
	setBrandLogo: (brandLogo) => set({ brandLogo }),
	setBrandColor: (brandColor) => set({ brandColor }),
	setSecondaryColor: (color) => set({ secondaryColor: color }),
	setLoading: (loading) => set({ loading }),
}));

export default useBrand;