export const allSourcesStyleInject = () => {
    return `
 .mainAllSources{
    height: 500px;
    overflow-y: auto;
    padding-block: 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
 }

 .sourcesContainer-exe{
  display: flex;
 flex-direction: column;
 gap:14px
 }

 .sourcesCard-exe{
 display: flex;
 flex-direction: column;
 gap:6px
 }
    `;
}